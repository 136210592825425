import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/slide1.jpeg";
import {TableRow, TableCell, Paper, TableBody, Table, TableContainer, TableHead} from "@mui/material";


const DarshanPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Darshan Timings</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <p>
                    <span>Daily Timing (at Sai Kulwant Hall)</span>
                    <br></br>
                    Bhagawan has laid down the daily schedule to be followed in the Ashram. Every activity of the Ashram routine is suffused with deep spiritual significance. See the table below for the Ashram schedule:
                    <br></br><br></br>
                    <TableContainer component={Paper}>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Time From</TableCell>
                                    <TableCell>Time To</TableCell>
                                    <TableCell>Activity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>05.10 a.m</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Aumkar, Meditation and Suprabhatham</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>05.40 a.m.</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Veda Chanting and Nagar Sankeertan (Circumambulating the Mandir)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>06.30 a.m.</TableCell>
                                    <TableCell>07.30 a.m.</TableCell>
                                    <TableCell>Pooja inside the Bhajan Hall (Entry for Devotees will start at 06.25 a.m.)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>8.00 a.m.</TableCell>
                                    <TableCell>9.00 a.m.</TableCell>
                                    <TableCell>Veda Chanting</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>9.00 a.m.</TableCell>
                                    <TableCell>9.30 a.m.</TableCell>
                                    <TableCell>Bhajans</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>9.30 a.m.</TableCell>
                                    <TableCell>10.30 a.m</TableCell>
                                    <TableCell>Darshan of Bhagawan’s Mahasamadhi and Prayer Hall</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>4.30 p.m.</TableCell>
                                    <TableCell>5.15 p.m.</TableCell>
                                    <TableCell>Veda Chanting</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>5.15 p.m.</TableCell>
                                    <TableCell>6.00 p.m</TableCell>
                                    <TableCell>Bhajans</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>6.00 p.m.</TableCell>
                                    <TableCell>7.00 p.m.</TableCell>
                                    <TableCell>Darshan of Bhagawan’s Mahasamadhi</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br></br>
                    <span>Extension of Darshan timings:</span> In response to requests by devotees, an additional time slot has been introduced on Sundays to facilitate Darshan of Bhagawan’s Sannidhi. Starting from September 7, 2014 (Onam Day), on all Sundays, devotees will be allowed to have Darshan of Bhagawan’s Sannidhi from 4.p.m. to 4.30.p.m, in addition to the current Darshan Timings after Bhajans.
                    <br></br>
                    <span>Late Evening Aarti:</span> To bring the Sannidhi Darshan to a ceremonial close and enhance the spiritual experience for the devotees, an Aarti will be offered at Bhagawan’s Sannidhi on all days towards the close of Evening Darshan, from September 4, 2014 onwards. This will be in addition to the usual Aarti offered after Evening Bhajans. Devotees will not be permitted to approach the Sannidhi after the last Aarti for the day.
                    <br></br>
                    <span>Kindly Note:</span>
                    <br></br>
                    <ul>
                    <li>Ladies and gents are seated separately in the hall with separate entrances for ladies and gents.</li>
                    <li>Devotees are generally allowed to be seated inside the Sai Kulwant Hall around 7.45 a.m. for the morning session and around 4.15 p.m. for the afternoon session.</li>
                    <li>The prayer sessions and darshan are open to all. There is no restriction on any basis whatsoever, be it religion, region, status, caste, etc.</li>
                    <li>There is no amount collected from anybody for Darshan.</li>
                    <li>Devotees are not permitted to carry along with them articles like books, bags, purses, mobile phones, cameras and other electronic goods etc.  into the Darshan Hall during Darshan time. Devotees may however bring their Veda books to the hall.</li>
                    <li>Electronics articles such as cell phones, can be kept in the cloak room located close to the entrance to the hall.</li>
                    </ul>
                    <br></br>
                    
                    </p>
                </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default DarshanPage
 