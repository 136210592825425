import React from "react"
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/narayanseva.jpg"

const NSPage = () => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Narayan Seva</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div>
                    <p>
                    Narayana Seva is one of the best Seva for feeding the poor and hungry people, who have never had in their lives the joy of eating a full meal. This is not mere charity. It is offering gratitude to God for giving us a chance to be of help to one's fellow men, who are all God's forms. 
                    <br></br>
                    Narayan seva is not poor feeding. When you do such a noble task, will you do it 'poorly' that you call it 'poor-feeding'? Dictionary does not have a proper translation for Narayan Seva which is an opportunity to feed the Lord Himself. " Narayana seva is feeding the hungry with reverence and humility and is the seva that is urgent today. Narayana seva; is service of God, not the service of Man. Sri Sathya Sai Narayana seva is being done in multiple ways depending on the need of the 'Narayana' or the recipients.
                    <br></br><br></br>

                    <span>Narayan Seva in Goa</span>
                    <br></br>
                    Most of the Samithi’s in Goa conduct Narayan Seva’s regularly every month,except Vasco samithi, who conduct Narayan Seva on weekly basis. The area includes slums, Railway station, Markets, Old age homes and Orphanages. Apart from this, Clothes are also distributed to needy ones.


                   </p>
                   </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>)
}

export default NSPage