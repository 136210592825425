import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/medicalcamp.jpg";

const MedicalPage = (props) => {
  return (
    <div className="textpage">
      <Navbar />
      <div className="events-container">
        <div className="title">
          <h2>Medical Camps</h2>
          <div className="underline" />
        </div>
        <div className="textpage-content">
          <div className="space" />
          <div className="textpage-container">
            <div className="image">
              <img src={slide}></img>
            </div>
            <p>
              Medicare has been high on the agenda of social activities of Sri
              Sathya Sai Seva Organisations worldwide, ever since Bhagawan has
              constructed a small model hospital in Puttaparthi way back in
              1960s to cater to the needs of Puttaparthi village. Over the years
              this small act of service has grown in gigantic proportions to
              come up with two world class Super Speciality Hospitals, one at
              Puttaparthi and the other in Bangalore. Inspired by Bhagawan’s
              message of providing free medical care to the poor and
              downtrodden, Sri Sathya Sai Seva Organisations, Goa are conducting
              free Medical camp at different places based on the necessity and
              requirement of the local Samithi’s, who organise all the necessary
              requirements like Doctor's, Pharmacist's, Sevadals and supply of
              medicines. There are two Mobile Medical Van that have been
              provided to Goa and both the vans are deployed at the location of
              the medical camps. The ambulance contains a stock of commonly
              prescribed medicines manned by a qualified pharmacist.
              <br></br>
              <br></br>
              With the divine blessings of Swami, first mobile medical van was
              donated to Goa on 15th Aug 2010 and was inaugurated by then Health
              minister of Goa, Shri Vishwajit Rane. The first camp was conducted
              at Chimbel village by Dr. Arun Bhobe and his team.
              <br></br>
              <br></br>
              The team of 30 Doctors are offering free Medical Consultation to
              this project.
              <br></br>
              This mobile medical Van visits different villages of Goa at the
              doorstep of the poorest of the poorest in rural areas according to
              the response of the patients. Villages covered are Banda in
              Maharashtra, Curca, Chimbel, Verlem, Murdi, Vasco(Mangor in
              Bogmalo), Tamdi Surla, Lakaki, Sakhubhat, Monte hill, Priol,
              Hanswada, Old Goa (slum Area).
              <br></br>A good team of qualified pharmacists, drivers mostly
              youths from our Organisation offer their regular Seva.
              <br></br>
              Two Static camps are also offering regular medical care:
              <ol>
                <li>By Dr. Sunil Chimbolkar at Karmali</li>
                <li>
                  By Dr.Arun Bhobe & Dr. Shaila Devari at Nageshi, Bandora.
                </li>
              </ol>
              <br></br>
              Recently on 8th February,2022, One more Medical Van has been
              donated to Goa to serve people in areas of South District.
              <br></br>
              <br></br>
              Until now, total patients treated in Goa are around 40,000.
            </p>
          </div>
          <div className="space" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MedicalPage;
