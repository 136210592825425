import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/slide1.jpeg"

const SCPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Study Circle</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <p>
                    <ul>
                        <li>Baba explains: What is a Study Circle ? It is not just reading books. Each person gives their point of view, and finally values are derived from this. If there is just reading there is doubt. But if each one gives his view, doubts will be answered. The study circle looks at different facets. It is like a diamond with its different facets, but there is one facet that is flat, the top facet, and from this all can be viewed. To discover the top facet is the task of the study circle.</li>
                        <br></br>
                        <li>A STUDY circle does not mean only just reading and discussing and taking information into the head, but also putting into practice what is learnt. If knowledge is stored in the mind, it causes confusion and confusion leads to blowing of the fuse. How will real jnana (spiritual wisdom) develop if there is too much confusion? For instance, if you go on eating all the 24 hours, it will result in indigestion. This will lead to disease. What is eaten should be digested and then only you should eat again. In the same way, you should listen (eat) in the Study Circle and put into practice (digest)what you have learnt. Again you can have another round of listening in the Study Circle. Now what you are doing is only loading and loading and no unloading. How much can you sustain like that? So, you should go on loading and unloading, listening and practising. You have to practise whatever you have learnt. Then only it becomes a real Study Circle.</li>
                        <br></br>
                        <li>Swami says ” Study with faith and devotion. Delve into the significance and meaning of what you read, and always have before you the goal of putting what you read into practice.</li>
                    </ul>
                    </p>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default SCPage
 