import { createTheme } from "@mui/material/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: '#FD6A00',
        }
    },
    typography: {
        fontSize: 24,
    },
})

export default theme;