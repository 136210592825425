import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/balvikas.jpg";
import {TableRow, TableCell, Paper, TableBody, Table, TableContainer, TableHead} from "@mui/material";


const BalVikasPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Sri Sathya Sai Balvikas</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p>
                    The Sri Sathya Sai Balvikas Program has been founded by Bhagawan Sri Sathya Sai Baba to enable a world-wide renewal of individual commitment to an active moral life. Bhagawan Baba says: "You must study to become steady. See to it that the Divine Love in your heart is steady. The goal of education should not be to just acquire theoretical knowledge but to practically apply it in your life. Birds and animals lead life without 'studying', but your education must result in you developing a strong and good character. Sri Sathya Sai Balvikas has thus been initiated and founded by Bhagawan Sri Sathya Sai Baba, based on the Divine Dictum "The End of Education is character."
                    <br></br><br></br>
                    Balvikas means the blossoming of Human Excellence. Human values can neither be imbibed through textbooks nor can they be gifted by anyone. They are inherent within each individual and the Sri Sathya Sai Balvikas program provides the right environment for these latent human values to blossom and empowers each child to unfold the innate values within oneself to the highest perfection.
                    <br></br><br></br>
                    Since the focus is on bringing an intrinsic transformation within each child. The Balvikas classes are conducted as a part of the Global Mission of the Sri Sathya Sai Seva Organisations to direct the children of today – the torch bearers of tomorrow’s society, into the path of self-enquiry and self-discovery.
                    <br></br><br></br>
                    With this conviction the Sri Sathya Sai Balvikas Program has been designed to help children to practice the basic human values viz; Sathya (Truth), Dharma (Righteousness), Shanthi (Peace), Prema (Love) and Ahimsa (Non Violence). Through the judicious use of simple teaching techniques such as: Prayer, Group Singing, Meditation, Storytelling and Group Activities, the Balvikas Gurus encourage the children to become conscious about the latent potential within and orient their lives on the trajectory of human excellence. This in short is the sum and substance of Sri Sathya Sai Balvikas Program.
                    <br></br><br></br>
                    <span>Objective of Sri Sathya Sai Balvikas</span>
                    <br></br>
                    Sri Sathya Sai Balvikas classes are conducted with the main objective of initiating the child into a course of character building by developing self-discipline, providing healthy association, inculcating such habits of reading, listening, studying, and reciting, as it will sublimate the child’s growing mind. This course commences with small children (Group I) between 6 and 9 years of age for creating interest in the child’s mind, for the collective discipline and instruction that is to follow when the child moves on to Group II between the age 9 and 12 years. During 5-6 years of this training, the child is expected to have derived the following benefits:
                    <br></br>
                    <ol>
                    <li>Having due respect for parents and other elders at home</li>
                    <li>Pay due attention to school studies and proper respect to the school teachers</li>
                    <li>Show good physical orderliness in respect of dress, behavior in school, society etc.</li>
                    <li>Develop self-confidence</li>
                    <li>Develop good manners, humility, speaking obligingly</li>
                    <li>Develop good moral character</li>
                    <li>Make good use of time by planning it in a balanced way between play, study, prayers etc.</li>
                    <li>Have spirit of service for those who need his/her help</li>
                    <li>Have love for God who expects us to be truthful, honest, good, loving, kind and helpful to all fellow beings.</li>
                    </ol>
                    <br></br>
                    After completing this course, the student is considered fit for the Group III, conducted for the children between 12 and 15 years. At this stage the student, besides getting a firmer grounding in theoretical and practical syllabus, receives further training (Unity of faiths, Fatherhood of God and Brotherhood of Man, Project work) which will widen his/her outlook on life, inculcate higher and nobler values of life.
                    <br></br><br></br>
                    A pre Sevadal activity and training course is conducted for children between 15 and 18 years of age. At the end of this, the student is considered fit to undergo training programs as a Balvikas Guru or Sevadal which will equip him/her to serve according to his/her aptitude, abilities and aspiration in Sri Sathya Sai Seva Organizations.
                    <br></br><br></br>
                    The Balvikas classes are conducted on weekly basis in Sri Sathya Sai Mandirs/ Schools/ Devotee Houses for 60-90 minutes only, through the use of five teaching techniques “ Silent sitting/ guided visualization, Story-telling, Prayers, Group singing, Group Activities.
                    <br></br><br></br>
                    Balvikas Gurus are well trained as per the guidelines issued by the All India President, Sri Sathya Sai Organizations, India.
                    <br></br><br></br>
                    The main stakeholders of this beautiful program are
                    <ol>
                    <li>BV Children</li>
                    <li>BV Gurus</li>
                    <li>Parents</li>
                    </ol> 
                    <br></br>

                    The state of Goa also conducts multiple Balvikas classes for all age groups across Goa and to know more about the location and timing, please feel free to contact our Balvikas Gurus:
                    <br></br><br></br>
                    <TableContainer component={Paper} className="bv-table">
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Samithi</TableCell>
                                    <TableCell>Balvikas Incharge</TableCell>
                                    <TableCell>Contact No.</TableCell>
                                    <TableCell>Balvikas Class Locations</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>Mapusa</TableCell>
                                    <TableCell>Annapurna</TableCell>
                                    <TableCell>8806757213</TableCell>
                                    <TableCell>Mahalaxmi temple ,Laxmi Nagar,Quitla, Aldona,Shirdi Sai Temple, Karaswada junction.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>2</TableCell>
                                    <TableCell>Panaji</TableCell>
                                    <TableCell>Rajeshree Nair</TableCell>
                                    <TableCell>9421155229</TableCell>
                                    <TableCell>Merces,Curca,Radha Krishna Temple.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>3</TableCell>
                                    <TableCell>Riabandar</TableCell>
                                    <TableCell>Jhanvi Mahale</TableCell>
                                    <TableCell>8805876777</TableCell>
                                    <TableCell>St. Peter's Chapel, saper.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>4</TableCell>
                                    <TableCell>Karmali</TableCell>
                                    <TableCell>Geeta Pawaskar</TableCell>
                                    <TableCell>8329988975</TableCell>
                                    <TableCell>Carrbhat,Old Goa,Parkebhat.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>5</TableCell>
                                    <TableCell>Ponda</TableCell>
                                    <TableCell>Naglaxmi</TableCell>
                                    <TableCell>9404437159</TableCell>
                                    <TableCell>Dhavli, Ponda</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>6</TableCell>
                                    <TableCell>Vasco</TableCell>
                                    <TableCell>Hema Neupane</TableCell>
                                    <TableCell>9422739914</TableCell>
                                    <TableCell>Baina,Mangoor,Vaddem,New Vaddem.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>7</TableCell>
                                    <TableCell>Margao</TableCell>
                                    <TableCell>Gauri Gaonkar</TableCell>
                                    <TableCell>9923724419</TableCell>
                                    <TableCell>Borda,Dicarpale,Pajifond,Sakubhat,Ramnagari.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>8</TableCell>
                                    <TableCell>Curchorem-Sanvordem</TableCell>
                                    <TableCell>Gayatri Raikar</TableCell>
                                    <TableCell>9689132728</TableCell>
                                    <TableCell>Behind Cine Prashant, Xeldem Grameen Kendra.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default BalVikasPage
 