import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/gramseva.jpg"

const GramSevaPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Gram Seva</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p>
                    Ever since the year 2000 when Swami first introduced the concept of Gram Seva, it has been growing in strength year after year. This is true not only in the Sri Sathya Sai University but all over India too with all the samithis and district and state organisations pitching in wonderfully.
                    <br></br>
                    This project,to start with, was executed by thousands of students from the 3 campuses of Sri Sathya Sai Institute of Higher Learning: the one at Anantapur, Brindavan near Bangalore and Prashanthi Nilayam, assisted by the students of Sri Sathya Sai Higher Secondary School, Prashanthi Nilayam. The students without any external help or support excusively carried out this task. A very unique feature of this project was its magnitude. The entire planning, organisation and execution were completed in a record time of just one month. There was a fortnight of planning and execution followed by a fortnight of the actual Grama Seva.
                    <br></br><br></br>
                    <span>Valuable Lessons Learnt From Gram Seva</span>
                    <br></br><br></br>
                    What lesson does Grama Seva offer to humanity? The first and the most important lesson is the quality of the effort that matters and not the quantity. And this brings to the next point – to serve with love,this means reaching out to one and all. The second lesson which Grama Seva has to offer is that of Nishkama Karma.
                    <br></br><br></br>
                    Bhagavan has often stated – “Each one of us works for happiness. Whatever we do in hinged around the simple, single pointed objective of achieving happiness.” But when we try to bring happiness to some others, happiness comes to us by itself.
                    <br></br><br></br>
                    In Goa some of Gram Seva activities conducted include Medical camps, Mother and Child Care Programs, House Construction/Repair work and cleaning Seva.
                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default GramSevaPage
 