import React, { useState } from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { TextField, Button } from "@mui/material";
import axios from "axios";

const Footer = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [msgError, setMsgError] = useState("");

  const [sent, setSent] = useState(false);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleSend = () => {
    if (!Boolean(name) || !emailRegex.test(email) || !Boolean(msg)) {
      setNameError(false);
      setEmailError(false);
      setMsgError(false);
      setSent(false);
      if (!Boolean(name)) {
        setNameError(true);
      }
      if (!Boolean(msg)) {
        setMsgError(true);
      }
      if (!emailRegex.test(email)) {
        setEmailError(true);
      }
    } else {
      setNameError(false);
      setEmailError(false);
      setMsgError(false);

      setSent(false);

      let data = new FormData();
      data.append("name", name);
      data.append("email", email);
      data.append("msg", msg);

      axios
        .post("https://api.ssssogoa.org/content/email/", data)
        .then((res) => {
          setSent(true);
        });
    }
  };

  return (
    <div className="footer-custom">
      <div className="footer-custom-container">
        <div className="footer-social">
          <h3>Follow Us</h3>
          <div className="follow-icons">
            <a
              href="https://www.facebook.com/srisathyasaigoa"
              target="_blank"
              className="facebook"
            >
              <FacebookOutlinedIcon />
            </a>
            <a
              href="https://instagram.com/srisathyasaigoa?igshid=YmMyMTA2M2Y="
              target="_blank"
              className="facebook"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://twitter.com/srisathyasaigoa?t=UqbsncD067xSklY3KBj4dQ&s=08"
              target="_blank"
              className="twitter"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <div className="contactus-form">
            <TextField
              className="form-text"
              label="Name"
              error={nameError}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              helperText={nameError ? "Enter your name" : ""}
            ></TextField>
            <TextField
              className="form-text"
              label="Email"
              error={emailError}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              helperText={emailError ? "Enter valid email" : ""}
            ></TextField>
            <TextField
              className="form-text"
              label="Message"
              multiline
              rows={3}
              error={msgError}
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              helperText={msgError ? "Please enter a message" : ""}
            ></TextField>
            <div className="form-button-container">
              <Button
                className="form-button"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSend();
                }}
              >
                Send
              </Button>
              <p>{sent ? "Message sent!" : ""}</p>
            </div>
          </div>
        </div>
        <div className="footer-address">
          <h3>Contact Us</h3>
          <p>
            'Sai prem',<br></br>
            Carrebhat,<br></br>
            Karmali,<br></br>
            Tiswadi Goa.<br></br>
            <br></br>
            Phone: +91 8329095563
            <br></br>
            Email: saiwebmessenger18@gmail.com
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-divider" />
        <p>Copyright &copy; 2022.</p>
      </div>
    </div>
  );
};

export default Footer;
