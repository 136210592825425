import React, { useEffect } from "react";
import { useState } from "react";
import Navbar from "../components/Navbar";
import Carousel from "nuka-carousel";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Card from "../components/Card";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { IconButton } from "@mui/material";
import { Carousel as CarouselBoot } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../components/Footer";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Home = () => {
  const [index, setIndex] = useState(0);
  const [indexD, setIndexD] = useState(0);
  const [vidIndex, setVidIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const [slides, setSlides] = useState([]);
  const [posts, setPosts] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      prevD();
    }, 5000);

    return () => clearInterval(interval);
  });

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleVidSelect = (selectedIndex, e) => {
    setVidIndex(selectedIndex);
  };

  const next = () => {
    if (index !== posts.length - 1) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };

  const prev = () => {
    if (index !== 0) {
      setIndex(index - 1);
    } else {
      setIndex(posts.length - 1);
    }
  };

  const totalSlides = Math.ceil(posts.length / 3);
  // const totalSlides = Math.floor(slides.length/3)
  const slideArray = Array(totalSlides).fill(3);

  const nextD = () => {
    if (indexD !== totalSlides - 1) {
      setIndexD(indexD + 1);
    } else {
      setIndexD(0);
    }
  };

  const prevD = () => {
    if (indexD !== 0) {
      setIndexD(indexD - 1);
    } else {
      setIndexD(totalSlides - 1);
    }
  };

  const nextVid = () => {
    if (vidIndex !== videos.length - 1) {
      setVidIndex(vidIndex + 1);
    } else {
      setVidIndex(0);
    }
  };

  const prevVid = () => {
    if (vidIndex !== 0) {
      setVidIndex(vidIndex - 1);
    } else {
      setVidIndex(videos.length - 1);
    }
  };

  useEffect(() => {
    axios.get("https://api.ssssogoa.org/content/events/").then((res) => {
      setEvents(res.data);
    });
    axios.get("https://api.ssssogoa.org/content/videos/").then((res) => {
      setVideos(res.data);
    });

    axios.get("https://api.ssssogoa.org/content/slides/").then((res) => {
      setSlides(res.data);
    });

    axios.get("https://api.ssssogoa.org/content/posts/").then((res) => {
      setPosts(res.data);
    });
  }, []);

  const style = {
    backgroundImage: "url('images/background2.jpeg') ",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  };

  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date1 = new Date(2022, 10, 22);
  const date2 = new Date(2022, 10, 8);
  const date3 = new Date(2022, 10, 24);
  const date4 = new Date(2022, 10, 28);

  const [calendarVal, setCalendarVal] = useState(null);

  return (
    <div className="homepage">
      <Navbar />
      <div className="slideshow-container">
        <Carousel wrapAround={true} autoplay={true} withoutControls={false}>
          {slides.map((obj, index) => {
            return (
              <img
                src={"https://api.ssssogoa.org" + obj.image}
                alt={obj.name}
              ></img>
            );
          })}
        </Carousel>
      </div>
      <div className="events-container">
        <div className="title">
          <h2>Popular Articles and What’s going on</h2>
          <div className="underline" />
        </div>
        <div className="events-card mobile-hidden">
          <IconButton
            className="left"
            onClick={() => {
              nextD();
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <CarouselBoot
            interval={null}
            activeIndex={indexD}
            onSelect={handleSelect}
            controls={false}
            indicators={false}
          >
            {slideArray.map((jot, i) => {
              return (
                <CarouselBoot.Item>
                  <div className="cards-slide">
                    {posts.slice(i * 3, i * 3 + 3).map((obj, index) => {
                      return <Card obj={obj} key={obj.id} />;
                    })}
                  </div>
                </CarouselBoot.Item>
              );
            })}
          </CarouselBoot>
          <IconButton
            className="right"
            onClick={() => {
              prevD();
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </div>
        <div className="events-card desktop-hidden">
          <IconButton
            className="left"
            onClick={() => {
              prev();
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <CarouselBoot
            interval={null}
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
            indicators={false}
          >
            {posts.map((obj, index) => {
              return (
                <CarouselBoot.Item>
                  <div className="cards-slide">
                    <Card obj={obj} />
                  </div>
                </CarouselBoot.Item>
              );
            })}
          </CarouselBoot>
          <IconButton
            className="right"
            onClick={() => {
              next();
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </div>
      </div>
      <div className="video-container" style={style}>
        <div className="video-container-backdrop"></div>

        <div className="title">
          <h2>Videos</h2>
          <div className="underline" />
        </div>
        <div className="events-card mobile-hidden">
          <IconButton
            className="left"
            onClick={() => {
              prevVid();
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <CarouselBoot
            interval={null}
            activeIndex={vidIndex}
            onSelect={handleVidSelect}
            controls={false}
            indicators={false}
          >
            {videos.map((obj, index) => {
              return (
                <CarouselBoot.Item key={obj.id}>
                  <div className="cards-slide">
                    <iframe
                      width="900"
                      height="506"
                      src={obj.url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </CarouselBoot.Item>
              );
            })}
          </CarouselBoot>
          <IconButton
            className="right"
            onClick={() => {
              nextVid();
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </div>
        <div className="events-card desktop-hidden">
          <IconButton
            className="left"
            onClick={() => {
              prevVid();
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <CarouselBoot
            interval={null}
            activeIndex={vidIndex}
            onSelect={handleVidSelect}
            controls={false}
            indicators={false}
          >
            {videos.map((obj, index) => {
              return (
                <CarouselBoot.Item key={obj.id}>
                  <div className="cards-slide">
                    <iframe
                      width="262"
                      height="147"
                      src={obj.url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </CarouselBoot.Item>
              );
            })}
          </CarouselBoot>
          <IconButton
            className="right"
            onClick={() => {
              nextVid();
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </div>
      </div>
      <div className="events-container">
        <div className="title">
          <h2>Locations of Sathya Sai Activities in Goa</h2>
          <div className="underline" />
        </div>
        <div className="events-card">
          <iframe
            className="mobile-hidden"
            src="https://www.google.com/maps/d/embed?mid=1SaZ_meoiOdiTQdLXV5AnmxuhMAcCCUk&ehbc=2E312F"
            width="1024"
            height="600"
          ></iframe>
          <iframe
            className="desktop-hidden"
            src="https://www.google.com/maps/d/embed?mid=1SaZ_meoiOdiTQdLXV5AnmxuhMAcCCUk&ehbc=2E312F"
            width="100%"
            height="400"
          ></iframe>
        </div>
      </div>
      <div className="events-container">
        <div className="title">
          <h2>Upcoming Events</h2>
          <div className="underline" />
        </div>
        <div className="calendar-container">
          <div className="calendar-left">
            <Calendar
              className="calendar"
              value={calendarVal}
              tileClassName="calendar-day"
            />
          </div>
          <div className="calendar-right">
            <div className="upcoming-list">
              {events.map((event, index) => {
                return (
                  <div
                    className="upcoming-event"
                    key={event.id}
                    onMouseOver={() => {
                      setCalendarVal(new Date(event.date));
                    }}
                    onMouseOut={() => {
                      setCalendarVal(null);
                    }}
                  >
                    <p className="upcoming-event-date">
                      <p>
                        {new Date(event.date).getDate() +
                          " " +
                          months[new Date(event.date).getMonth()] +
                          " " +
                          new Date(event.date).getFullYear()}
                      </p>
                    </p>
                    <p className="upcoming-event-name">{event.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
