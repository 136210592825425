import React from "react";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

const Card = (props) => {
  const date = new Date(props.obj.date);

  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="card">
      <img src={"https://api.ssssogoa.org" + props.obj.image}></img>
      <div className="header">
        <div className="location">
          <LocationOnRoundedIcon />
          <span>{props.obj.venue}</span>
        </div>
        <p>
          {date.getDate() +
            " " +
            months[date.getMonth()] +
            " " +
            date.getFullYear()}
        </p>
      </div>
      <p>{props.obj.text}</p>
    </div>
  );
};

export default Card;
