import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/vedam.jpg"

const ImgPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Vedam</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p><span>What did Swami say about Vedam?</span>
                        <br></br>
                        “‘Vēda’ means knowledge; it always is. It has neither beginning nor end. It is referred to as an-anta, without end; for it is sound sacred, sustaining, and salutary. It is to be only experienced; it cannot be limited or communicated. Therefore, it is a marvel, an unprecedented phase of personal experience for each one.”
                        <br></br>
                        <span>Bhagavān Sri Sathya Sai Baba – Sutra Vahini, Chap. 3</span>
                        <br></br><br></br>
                        “Vēdas are very old, have no describable origin and are timeless. Vēdas do not need support from any quarter as they support themselves. In fact, Vēdas are God’s Breath. […] God is present everywhere, God is omnipresent. Therefore, Vēdas, which simply constitute the Breath of God, are also omnipresent, i.e. they are everywhere. The sounds of Vēdas, the essence of Vēdas, the fragrance of Vēdas are indeed to be found all over the world.”
                        <br></br>
                        <span>Bhagavān Sri Sathya Sai Baba – Summer Showers in Brindavan 1972, Chap. 2</span>
                        <br></br><br></br>
                        “The Vēdas are Eternal Truths. They existed even before the people of this land discovered, practiced and experienced them. Just as Western physicists announced the existence of gravitation after their experiments, the ancients of this land demonstrated the innate authenticity of the Vēdas through their own experience. Here too, the Vēda existed long before it was discovered and put into practice. Newton’s Laws of Gravitation benefited the whole world. They express universal truths applicable to all places and times. They are not confined to Western countries only. So too, the Vēda is Truth, not merely for Bhārat but for all people on earth.”
                        <br></br>
                        <span>Bhagavān Sri Sathya Sai Baba – Sathya Sai Vahini, Chap. 22</span>
                        <br></br><br></br>
                        <span>Ongoing Vedam Activities in Goa State</span>
                        <br></br><br></br>
                        Maharudram Parayan
                        <br></br>
                        Bhagawan Sri Sathya Sai Baba has always emphasised the importance of chanting Sri Rudram and extolled its benefits as well in a hope of being a true instrument of His Divine Mission. We the devotees of Goa have made it a regular feature to offer Maharudra parayan every alternate month at all Goa level and Laghurudram at Samithi level. It's a small offering permeating the atmosphere with divine vibrations regularly and benefiting all humanity at a larger level.
                        <br></br><br></br>
                        Laghu Rudram Parayan
                        <br></br><br></br>
                        Annual Ashadi Celebrations at Prasanthi Nilayam by Goa Vedam Team
                        <br></br>
                        Ashadi Ekadashi is one such beautiful festival where all the devotees from Goa visit Prasanthi Nilayam, Puttaparthi to get the glimpse of their beloved Sai Vitthala. Devotees of Goa and Maharashtra get an opportunity to chant Vedam in the Divine presence of Bhagawan and are truly grateful to Sai Maa for giving us this opportunity.
                        <br></br><br></br>
                        Atharvshirsham Parayan
                        <br></br>
                        Goa Falls in the Konkan belt and its known fact that Ganpati is the most revered deity so Atharvshirsh parayan is held monthly on Sankashti day in  temples or devotees homes by respective Sai Samithi and it’s heartening to see a large number of locals who are non sai devotees also participating with devotion and fervor.
                        <br></br><br></br>
                        Weekly online Vedam classes
                        <br></br>
                        We also conduct online Vedam classes for elderly devotees and ladies who are desirous of learning but due to family obligations are unable to travel. They find it very convenient and are truly grateful to Swami for giving them this opportunity. Apart from online Vedam classes we also conduct weekly Vedam practice classes at Samithi level and also during weekly Balvikas classes.
                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ImgPage
 