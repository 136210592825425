import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/amrutkalash.jpg"

const AmrutPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Amrut Kalash</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p>
                    Sri Sathya Sai Amrutha Kalasam is a unique way of providing monthly ration to poor people in remote localities of towns and villages. Initially a survey is conducted by volunteers of SSSSO to identify people or families having very low income and struggling to earn their livelihood. A monthly package of Rice, Dal, Cooking oil, turmeric, chillies, Tea, sugar, soaps, toothpaste etc. sufficient for 2-3 persons is handed over to them at their doorstep. This is an ongoing activity undertaken by Sri Sathya Sai Seva Samithis and  Amrutha Kalashams are distributed by members of the Organisation every month. Devotees and Sevadal who participates in this service activity experience bliss of Swami and derive immense satisfaction and are often seen to encourage others also to be a part of it.
                    <br></br><br></br>
                    In Goa,every samithis do regular monthly Amrutha Kalasam distributions. During the tough Pandemic time, Our State Organisation distributed around 608 bags all across Goa with the permission of State authorities.

                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AmrutPage
 