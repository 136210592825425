import React, { useEffect, useState } from "react";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import {
  IconButton,
  ListItemIcon,
  ListItemButton,
  Box,
  List,
  Drawer,
  ListItemText,
  Collapse,
  Menu,
  MenuItem,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import logoright from "../images/logo-right.png";
import logoleft from "../images/logo-left.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PauseCircleFilledOutlinedIcon from "@mui/icons-material/PauseCircleFilledOutlined";
import audio from "../music/audio.mp3";

const Navbar = (props) => {
  const [sound, setSound] = useState(false);

  const [menuToggle, setMenuToggle] = useState(false);
  const [about, setAbout] = useState(false);
  const [ashram, setAshram] = useState(false);
  const [wings, setWings] = useState(false);

  const [aboutD, setAboutD] = useState(false);
  const [ashramD, setAshramD] = useState(false);
  const [wingsD, setWingsD] = useState(false);
  const [contactD, setContact] = useState(false);

  const [timelineClass, setTimelineClass] = useState("timeline-collapse");
  const [ashramClass, setAshramClass] = useState("timeline-collapse");
  const [wingsClass, setWingsClass] = useState("wings-collapse");

  const [route, setRoute] = useState(false);
  const [acco, setAcco] = useState(false);

  const [spirit, setSpirit] = useState(false);
  const [serve, setServe] = useState(false);
  const [edu, setEdu] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    var x = document.getElementById("myAudio");

    if (!sessionStorage.getItem("audio")) {
      x.play();
      sessionStorage.setItem("audio", true);
      setSound(true);
    }
    setSound(sessionStorage.getItem("audio") === "true" ? true : false);
    if (sessionStorage.getItem("audio") === "true") {
      x.play();
    } else {
      x.pause();
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <List className="navbar-list">
        <Link to="/">
          <ListItemButton>
            <ListItemText primary="Home" />
          </ListItemButton>
        </Link>
        <ListItemButton
          onClick={() => {
            setAbout(!about);
          }}
        >
          <ListItemText primary="Timeline" />
          {about ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={about} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to="/timeline/visit">
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Bhagawan Sri Sathya Sai Baba's Visit To Goa" />
              </ListItemButton>
            </Link>
            <Link to="/timeline/history">
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="History of Goa Organisation" />
              </ListItemButton>
            </Link>
            <Link to="/about/officebearers">
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="State Office Bearers" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItemButton
          onClick={() => {
            setAshram(!ashram);
          }}
        >
          <ListItemText primary="Ashram Information" />
          {ashram ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={ashram} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to="/ashram/darshan">
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Darshan Timing" />
              </ListItemButton>
            </Link>
            <Link to="/ashram/accomodation">
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  setAcco(!acco);
                }}
              >
                <ListItemText primary="Accomodation" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItemButton>
          <ListItemText
            primary="Organisational Wings"
            onClick={() => {
              setWings(!wings);
            }}
          />
          {wings ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={wings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                setSpirit(!spirit);
              }}
            >
              <ListItemText primary="Spiritual" />
              {spirit ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={spirit} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/wings/spiritual/bhajanmandalis">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Bhajan Centers" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/spiritual/mandalis">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Bhajan Mandalis" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/spiritual/studycircle">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Study Circle" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/spiritual/vedam">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Vedam" />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                setServe(!serve);
              }}
            >
              <ListItemText primary="Service" />
              {serve ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={serve} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/wings/service/narayanseva">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Narayan Seva" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/service/amruth">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Amrut Kalash" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/service/blooddonation">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Blood Donation Camps" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/service/gramseva">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Gram Seva" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/service/medicalcamps">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Medical Camps" />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                setEdu(!edu);
              }}
            >
              <ListItemText primary="Educational" />
              {edu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={edu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/wings/educational/balvikas">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="Balvikas" />
                  </ListItemButton>
                </Link>
                <Link to="/wings/educational/sssvj">
                  <ListItemButton sx={{ pl: 6 }}>
                    <ListItemText primary="SSSVJ" />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </List>
        </Collapse>
        {/* <ListItemButton>
                <ListItemText primary="Contact Us"/>
            </ListItemButton> */}
      </List>
    </Box>
  );

  return (
    <div className="navbar-custom">
      <div className="navbar-top-desktop">
        <div className="logo-left">
          <img src={logoright} />
        </div>
        <div className="sai-name">
          <h1>Sri Sathya Sai Seva Organisations</h1>
          <p>Goa</p>
        </div>
        <div className="logo-right">
          <img src={logoleft} />
        </div>
      </div>
      <audio id="myAudio">
        <source src={audio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <div className="navbar-bottom">
        <div className="button-container desktop-hidden">
          <ClickAwayListener
            onClickAway={() => {
              setMenuToggle(false);
              setAbout(false);
              setAshram(false);
              setWings(false);
            }}
          >
            <div>
              <IconButton
                color="primary"
                onClick={() => {
                  setMenuToggle(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer variant="persistent" anchor="left" open={menuToggle}>
                {list()}
              </Drawer>
            </div>
          </ClickAwayListener>
          <IconButton
            className="desktop-play-button"
            color="primary"
            onClick={() => {
              var x = document.getElementById("myAudio");
              setSound(!sound);
              sessionStorage.setItem("audio", !sound);
              if (!sound) {
                x.play();
              }
              if (sound) {
                x.pause();
              }
            }}
          >
            {sound ? (
              <PauseCircleFilledOutlinedIcon />
            ) : (
              <PlayCircleFilledOutlinedIcon />
            )}
          </IconButton>
        </div>
        <div className="links-container mobile-hidden">
          <div className="tab">
            <Link to="/">Home</Link>
          </div>
          <div
            className="tab"
            onMouseOver={() => {
              setTimelineClass("timeline-expand");
            }}
            onMouseOut={() => {
              setTimelineClass("timeline-collapse");
            }}
          >
            <a href="#">Timeline</a>
            <div className={timelineClass}>
              <div className="expand-bg"></div>
              <ul>
                <Link to="/timeline/visit">
                  <li>Bhagawan Sri Sathya Sai Baba's Visit To Goa</li>
                </Link>
                <Link to="/timeline/history">
                  <li>History of Goa Organisation</li>
                </Link>
                <Link to="/about/officebearers">
                  <li>State Office Bearers</li>
                </Link>
              </ul>
            </div>
          </div>
          <div
            className="tab"
            onMouseOver={() => {
              setAshramClass("timeline-expand");
            }}
            onMouseOut={() => {
              setAshramClass("timeline-collapse");
            }}
          >
            <a href="#">Ashram Information</a>
            <div className={ashramClass}>
              <div className="expand-bg"></div>
              <ul>
                <Link to="/ashram/darshan">
                  <li>Darshan Timing</li>
                </Link>
                <Link to="/ashram/accomodation">
                  <li>Accomodation</li>
                </Link>
              </ul>
            </div>
          </div>
          <div
            className="tab"
            onMouseOver={() => {
              setWingsClass("wings-expand");
            }}
            onMouseOut={() => {
              setWingsClass("wings-collapse");
            }}
          >
            <a href="#">Organisational Wings</a>
            <div className={wingsClass}>
              <div className="expand-bg"></div>
              <ul>
                <li className="link-title">Spiritual</li>
                <Link to="/wings/spiritual/bhajanmandalis">
                  <li>Bhajan Centers</li>
                </Link>
                <Link to="/wings/spiritual/mandalis">
                  <li>Bhajan Mandalis</li>
                </Link>
                <Link to="/wings/spiritual/studycircle">
                  <li>Study Circle</li>
                </Link>
                <Link to="/wings/spiritual/vedam">
                  <li>Vedam</li>
                </Link>
              </ul>
              <ul>
                <li className="link-title">Service</li>
                <Link to="/wings/service/narayanseva">
                  <li>Narayan Seva</li>
                </Link>
                <Link to="/wings/service/amruth">
                  <li>Amrut Kalash</li>
                </Link>
                <Link to="/wings/service/blooddonation">
                  <li>Blood Donation Camps</li>
                </Link>
                <Link to="/wings/service/gramseva">
                  <li>Gram Seva</li>
                </Link>
                <Link to="/wings/service/medicalcamps">
                  <li>Medical Camps</li>
                </Link>
              </ul>
              <ul>
                <li className="link-title">Education</li>
                <Link to="/wings/educational/balvikas">
                  <li>Balvikas</li>
                </Link>
                <Link to="/wings/educational/sssvj">
                  <li>SSSVJ</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="play-container mobile-hidden">
          <IconButton
            color="primary"
            onClick={() => {
              var x = document.getElementById("myAudio");
              setSound(!sound);
              sessionStorage.setItem("audio", !sound);
              if (!sound) {
                x.play();
              }
              if (sound) {
                x.pause();
              }
            }}
          >
            {sound ? (
              <PauseCircleFilledOutlinedIcon />
            ) : (
              <PlayCircleFilledOutlinedIcon />
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
