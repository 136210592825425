import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/history.jpg"

const HistoryPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>History of Goa Organisation</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p>
                    The movement of Sai Organisation in Goa started after the Divine visit of Bhagawan Sri Sathya Sai Baba in the year 1968 and 1970.
                    <br></br>
                    By 1977, there were three Bhajan mandalis started at Panaji, Vasco and Margao. However there were devotees especially in Karmali, Ponda and Curchorem-Sanvordem also. 
                    In April 1985, State office bearers from Maharashtra visited Goa and appraised Goa devotees about Sri Sathya Sai Seva Organisation. One exhibition at Shri Vidyadhiraj Bhavan, Vasco was organised. On this occasion devotees from Margao,
                    Curchorem-Sanvordem, Panaji and Ponda participated.
                    <br></br>
                    In the Year 1985, 65 devotees from Goa went on a holy pilgrimage to Prashanti Nilayam, Puttaparthi as a organised group to participate in Swami’s 60th birthday celebrations on 23rd November.
                    <br></br>
                    After returning from Puttaparthi, devotees started activities in their respective towns/cities and thus Sai movement of  Goa organisation started.

                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default HistoryPage
 