import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import slide from "../images/slide1.jpeg"

const TablePage = () => {

    return(
        <div className="tablepage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>State office bearers</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <TableContainer component={Paper}>
                        <Table >
                            <TableBody>
                                <TableRow>
                                    <TableCell>State President</TableCell>
                                    <TableCell>Shri Mahesh Pawaskar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Vice President</TableCell>
                                    <TableCell>Shri Saieesh Gandhi</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>North Goa District President</TableCell>
                                    <TableCell>Shri Pramod Divgi</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>South Goa District President</TableCell>
                                    <TableCell>Shri Narayana Rao Boddu</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Spiritual Coordinator</TableCell>
                                    <TableCell>Shri Girish Halgekar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Education Coordinator</TableCell>
                                    <TableCell>Kum. Pranita Kankonkar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Youth Coordinator (Gents)</TableCell>
                                    <TableCell>Shri Siddhesh Prabhudesai</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Youth Coordinator (Mahila)</TableCell>
                                    <TableCell>Smt. Sarika Deshmukh</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Veda Coordinator (Gents)</TableCell>
                                    <TableCell>Shri Saineel Dharwatkar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Veda Coordinator (Mahila)</TableCell>
                                    <TableCell>Smt. Sunetra kamat Tarcar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Seva Coordinator (Gents)</TableCell>
                                    <TableCell>Shri Anil Pednekar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Seva Coordinator(Mahila)</TableCell>
                                    <TableCell>Smt. Kanchan Tarcar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Media Team</TableCell>
                                    <TableCell>Shri Sujit Chimbolkar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Media Team</TableCell>
                                    <TableCell>Shri Pramod Divgi</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>State Media Team</TableCell>
                                    <TableCell>Smt. Rajeshri Nair</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Disaster Management Coordinator</TableCell>
                                    <TableCell>Shri Omkar Pawaskar</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="space"/>
                </div>
            </div>
            <div className='events-container'>
                <div className='title'>
                    <h2>Office Bearers of North Goa District</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <TableContainer component={Paper}>
                        <Table >
                            <TableBody>
                                <TableRow>
                                    <TableCell>Spiritual coordinator</TableCell>
                                    <TableCell>Shri Mahendra Bhandari</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bhajan Coordinator (Gents)</TableCell>
                                    <TableCell>Shri Ramdas Shankardas</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bhajan Coordinator (Mahila)</TableCell>
                                    <TableCell>Smt. Tanvi Walawalkar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Youth Coordinators (Gents)</TableCell>
                                    <TableCell>Shri Sushant Colvenkar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Youth Coordinators (Mahila)</TableCell>
                                    <TableCell>Smt. Sonia Chodankar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Seva Coordinator (Gents)</TableCell>
                                    <TableCell>Shri Somnath Nair</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Seva Coordinator (Mahila)</TableCell>
                                    <TableCell>Smt. Nutan Kundaikar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Veda Coordinator (Mahila)</TableCell>
                                    <TableCell>Smt. Sujata Chimbolkar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Balvikas Coordinator</TableCell>
                                    <TableCell>Smt. Pranati Mohanti</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Samithi Convenor Karmali</TableCell>
                                    <TableCell>Shri Shailesh Kavlekar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Samithi Convenor Ribandar</TableCell>
                                    <TableCell>Shri Shobhan Naik</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Samithi Convenor Panaji</TableCell>
                                    <TableCell>Shri Parthish Vernekar</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Samithi Convenor Mapusa</TableCell>
                                    <TableCell>Shri Gajanan Naik</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Samithi Convenor Kundaim</TableCell>
                                    <TableCell>Shri Paritosh Sakate</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default TablePage;