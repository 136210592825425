import './App.css';
import { ThemeProvider } from '@mui/system';
import theme from './theme';
import Home from './pages/home';
import TextPage from './pages/TextPage';
import TablePage from './pages/TablePage';
import NSPage from './pages/NSPage';
import ImgPage from './pages/ImgPage';
import SCPage from './pages/SCPage';
import BalVikasPage from './pages/BalVikasPage';
import AccoPage from './pages/AccoPage';
import HistoryPage from './pages/HistoryPage';
import VisitPage from './pages/VisitPage';
import MedicalPage from './pages/MedicalPage';
import GramSevaPage from './pages/GramSevaPage';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import BloodPage from './pages/BloodPage';
import DarshanPage from './pages/DarshanPage';
import SSSVJPage from './pages/SSSVJPage';
import AmrutPage from './pages/AmrutPage';
import SBCPage from './pages/SBCPage';
import MandaliPage from './pages/MandaliPage';

function App() {
  

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/about/officebearers" element={<TablePage/>}></Route>
            <Route path="/wings/educational/balvikas" element={<BalVikasPage/>}></Route>
            <Route path="/wings/educational/sssvj" element={<SSSVJPage/>}></Route>
            <Route path="/wings/spiritual/vedam" element={<ImgPage/>}></Route>
            <Route path="/wings/spiritual/studycircle" element={<SCPage/>}></Route>
            <Route path="/ashram/accomodation" element={<AccoPage/>}></Route>
            <Route path="/wings/service/narayanseva" element={<NSPage/>}></Route>
            <Route path="/timeline/history" element={<HistoryPage/>}></Route>
            <Route path="/timeline/visit" element={<VisitPage/>}></Route>
            <Route path="/wings/service/medicalcamps" element={<MedicalPage/>}></Route>
            <Route path="/wings/service/gramseva" element={<GramSevaPage/>}/>
            <Route path="/wings/service/blooddonation" element={<BloodPage/>}/>
            <Route path="/ashram/darshan" element={<DarshanPage/>}/>
            <Route path="/wings/service/amruth" element={<AmrutPage/>}></Route>
            <Route path="/wings/spiritual/bhajanmandalis" element={<SBCPage/>}/>
            <Route path="/wings/spiritual/mandalis" element={<MandaliPage/>}/>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
