import React from "react"
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/slide1.jpeg"
import {TableRow, TableCell, Paper, TableBody, Table, TableContainer, TableHead} from "@mui/material";


const SBCPage = () => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Samithi Bhajan Center’s</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <p>
                    When all the participants in a bhajan sing in unison, what sacred vibrations are produced and what Divine energies are released! When these vibrations fill the world, what changes cannot they bring about! When one sings alone, the heart is merged in the song. But when many sing together, it acquires a Divine power.
                    <br></br>
                    <span>-Sri Sathya Sai Baba.</span>
                    <br></br><br></br>
                    Bhajans/ Group devotional singing is one of the fundamental practices of the Sri Sathya Sai Seva Samithi’s. Bhajans are held mostly once a week on Thursday or weekends and special occasions. Bhajans are either held in public places or individual houses. The bhajan sessions are mostly one hour durations and follow a format of:
                    <ul>
                    <li>Vedam chanting</li>
                    <li>Sathya Sai Gayatri</li>
                    <li>Bhajans</li>
                    <li>Sai Sandesh</li>
                    <li>Aarthi</li>
                    </ul>
                    <br></br>
                    To know more about bhajan locations in North Goa, You can contact respective Samithi Convenors
                    <br></br><br></br>
                    <TableContainer component={Paper} className="bv-table">
                        <Table style={{ tableLayout: 'fixed' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="sr-col">Sr. No.</TableCell>
                                    <TableCell className="sa-col">Samithi</TableCell>
                                    <TableCell className="sc-col">Samithi Convenor</TableCell>
                                    <TableCell className="cn-col">Contact No.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>Mapusa</TableCell>
                                    <TableCell>Gajanan Naik</TableCell>
                                    <TableCell>9637735841</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>2</TableCell>
                                    <TableCell>Panaji</TableCell>
                                    <TableCell>Partish Pai Vernekar</TableCell>
                                    <TableCell>9822102463</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>3</TableCell>
                                    <TableCell>Ribandar</TableCell>
                                    <TableCell>Shoban Naik</TableCell>
                                    <TableCell>9673995495</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>4</TableCell>
                                    <TableCell>Karmali</TableCell>
                                    <TableCell>Santosh Naik</TableCell>
                                    <TableCell>9822104435</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>5</TableCell>
                                    <TableCell>Kundaim</TableCell>
                                    <TableCell>Paritosh Sakate</TableCell>
                                    <TableCell>9765253260</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br></br>
                    To know more about bhajan locations in South Goa, You can contact respective Samithi Convenors
                    <br></br><br></br>
                    <TableContainer component={Paper} className="bv-table">
                        <Table style={{ tableLayout: 'fixed'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="sr-col">Sr. No.</TableCell>
                                    <TableCell className="sa-col">Samithi</TableCell>
                                    <TableCell className="sc-col">Samithi Convenor</TableCell>
                                    <TableCell className="cn-col">Contact No.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>Ponda</TableCell>
                                    <TableCell>Prashant Shivram Pavse</TableCell>
                                    <TableCell>9890249769</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>2</TableCell>
                                    <TableCell>Vasco</TableCell>
                                    <TableCell>Mahesh Singbal</TableCell>
                                    <TableCell>9822177313</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>3</TableCell>
                                    <TableCell>Margao</TableCell>
                                    <TableCell>Raghavendra Gouda</TableCell>
                                    <TableCell>9923724419</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>4</TableCell>
                                    <TableCell>Curchorem-Sanvordem</TableCell>
                                    <TableCell>Partish Prabhuchodnekar</TableCell>
                                    <TableCell>9822556008</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                   </p>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>)
}

export default SBCPage