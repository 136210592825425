import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {TableRow, TableCell, Paper, TableBody, Table, TableContainer, TableHead} from "@mui/material";

const MandaliPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Samithi Bhajan Mandalis</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <p>
                    <span>Samithi Bhajan Mandalis</span>
                    <br></br><br></br>
                    North Goa
                    <br></br><br></br>
                    <TableContainer component={Paper} style={{ maxWidth:800 }} className="bv-table">
                        <Table style={{ tableLayout: 'fixed', maxWidth:800 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Samithis</TableCell>
                                    <TableCell align="center">Number Of Bhajan Mandalis</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">1</TableCell>
                                    <TableCell align="center">Mapusa</TableCell>
                                    <TableCell align="center">2</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">2</TableCell>
                                    <TableCell align="center">Panaji</TableCell>
                                    <TableCell align="center">1</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">3</TableCell>
                                    <TableCell align="center">Riabandar</TableCell>
                                    <TableCell align="center">0</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">4</TableCell>
                                    <TableCell align="center">Karmali</TableCell>
                                    <TableCell align="center">2</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">5</TableCell>
                                    <TableCell align="center">Kundaim</TableCell>
                                    <TableCell align="center">0</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br></br><br></br>
                    <span>Samithi Bhajan Mandalis</span>
                    <br></br><br></br>
                    South Goa
                    <br></br><br></br>
                    <TableContainer component={Paper} style={{ maxWidth:800 }}>
                        <Table style={{ tableLayout: 'fixed' }} className="bv-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Samithis</TableCell>
                                    <TableCell align="center">Number Of Bhajan Mandalis</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">1</TableCell>
                                    <TableCell align="center">Ponda</TableCell>
                                    <TableCell align="center">1</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">2</TableCell>
                                    <TableCell align="center">Vasco</TableCell>
                                    <TableCell align="center">2</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">3</TableCell>
                                    <TableCell align="center">Margao</TableCell>
                                    <TableCell align="center">2</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">4</TableCell>
                                    <TableCell align="center">Curchorem-Sanvordem</TableCell>
                                    <TableCell align="center">1</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default MandaliPage
 