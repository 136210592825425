import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/blooddonation.jpg";
import {TableRow, TableCell, Paper, TableBody, Table, TableContainer, TableHead} from "@mui/material";


const BloodPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Blood Donation Camps</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p>
                    Sri Sathya Sai Seva Organisations, Goa organises three Blood Donation (Liquid Love) Camps in a calendar year. On 26th January, 3rd Sunday of June and 20th November in collaboration with Goa Medical College Blood Bank, Bambolim. Apart from this, Volunteers of our Organisation also respond for any urgent blood donation in case of any emergency by voluntarily donating blood.
                    <br></br>
                    <br></br>
                    <TableContainer component={Paper}>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Sr. No.</TableCell>
                                    <TableCell align="center">Year</TableCell>
                                    <TableCell align="center">No. of Donors</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">1</TableCell>
                                    <TableCell align="center">2011</TableCell>
                                    <TableCell align="center">67</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">2</TableCell>
                                    <TableCell align="center">2012</TableCell>
                                    <TableCell align="center">61</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">3</TableCell>
                                    <TableCell align="center">2013</TableCell>
                                    <TableCell align="center">40</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">4</TableCell>
                                    <TableCell align="center">2014</TableCell>
                                    <TableCell align="center">47</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">5</TableCell>
                                    <TableCell align="center">2015</TableCell>
                                    <TableCell align="center">102</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">6</TableCell>
                                    <TableCell align="center">2016</TableCell>
                                    <TableCell align="center">97</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">7</TableCell>
                                    <TableCell align="center">2017</TableCell>
                                    <TableCell align="center">100</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">8</TableCell>
                                    <TableCell align="center">2018</TableCell>
                                    <TableCell align="center">93</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">9</TableCell>
                                    <TableCell align="center">2019</TableCell>
                                    <TableCell align="center">118</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">10</TableCell>
                                    <TableCell align="center">2020</TableCell>
                                    <TableCell align="center">51</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">11</TableCell>
                                    <TableCell align="center">2021</TableCell>
                                    <TableCell align="center">46</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">12</TableCell>
                                    <TableCell align="center">2022</TableCell>
                                    <TableCell align="center">60</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Total Donors</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">882</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </p>
                </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default BloodPage
 