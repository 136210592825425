import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/accomodation.jpg"

const AccoPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Accomodation</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p>Advance booking of accommodation may be done online. This facility is presently for those seeking to book family accommodation.
                    <br></br>
                    <br></br>
                    <a href="http://www.prasanthinilayam.in/" target="_blank" className="booking-link">Booking Link</a>
                    <br></br><br></br>
                    <span>Citizens of India and Nepal</span>
                    <br></br>
                    Dormitory for individual visitors
                    <br></br>
                    Office is located at Ganesh gate entrance to the Ashram.
                    <br></br>
                    Timings: 5.00 a.m. - 9.00 p.m.
                    <br></br>
                    Tariff (per head per day): Rs. 5 | Rs. 10 | Rs. 30 (with bunker beds)
                    <br></br>
                    <br></br>
                    Rooms (for families)
                    <br></br>
                    Office is located adjacent to North - 9 Block.
                    <br></br>
                    Timings: 6.15 a.m. - 8.00 p.m.
                    <br></br>
                    Tariff (per day):
                    <ul>
                    <li>Rs. 100 (unfurnished with Single Cot)</li>
                    <li>Rs. 150 (semi-furnished with 2 cots)</li>
                    <li>Rs. 250/ Rs. 300 (Furnished with 2 cots, excluding North Block rooms)</li>
                    <li>Rs. 50 extra for rooms with hot water facility.</li>
                    </ul>
                    <br></br>
                    <span>Kindly Note:</span>
                    <ul>
                    <li>Valid Photo ID: Valid Passport, Driving License, Voter ID Card, PAN Card, Ration Card, Photo identity cards issued by Central/State Government for their employees, Nationalised Bank Passbook with photograph, Student Identity Card with photograph issued by recognized School / College for their students/ Aadhar card.</li>
                    <li>Nepali Citizens must necessarily carry their valid passports.</li>
                    <li>Individuals are provided Dormitory accommodation.</li>
                    <li>A day is 24 hours from the check-in time.</li>
                    </ul>
                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AccoPage
 