import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import slide from "../images/SSSVJ.jpg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SSSVJPage = (props) => {
    return(
        <div className="textpage">
            <Navbar/>
            <div className='events-container'>
                <div className='title'>
                    <h2>Sri Sathya Sai Vidya Jyothi (SSSVJ)</h2>
                    <div className='underline'/>
                </div>
                <div className='textpage-content'>
                    <div className="space"/>
                    <div className="textpage-container">
                    <div className="image"><img src={slide}></img></div> 
                    <p>
                    <span>Objectives</span>
                    <br></br>
                    Inspiring children by Empowering Schools
                    <br></br><br></br>
                    Our program aims to supplement and enhance the levels of motivation and learning experience of students of the schools adopted under the program, by leveraging and improving the ecosystem in which the schools operate.
                    <br></br><br></br>
                    Sri Sathya Sai Vidya Jyothi programme, launched in 2016, has gained full momentum across the country with the active participation of thousands of samithis in the task ahead. The aim of this programme is to select existing, semi operational, makeshift, dilapidated schools and turn them into fully operational, well structured, collaborative schools, ultimately emerging into good healthy learning centres.
                    <br></br><br></br>
                    Since a school is more than just a brick and mortar structure, the program will take a more holistic view of the entire ecosystem. Multiple stakeholders like the parent, the teacher, the school staff, the student, the villager, the volunteer and even the government department is approached, interviewed, involved and leveraged on a need basis, in constructively shaping the learning environment for the child. 
                    <br></br><br></br>
                    The Sri Sathya Sai Seva Organisations (SSSSO) has undertaken this program for two important reasons: 
                    <br></br>
                    <ol>
                    <li>To increase sadhana-oriented Engagement through a common project at national level.</li>
                    <li>For increasing value-adding Outreach, especially of new youth.</li>
                    </ol>
                    <ol>
                    <li><span>Engagement:</span> The SSSSO has many Sai Centers/Samithis spread all over the world, including India. Within these Samithis there are also many Vibhags like Mahilas, Youth, Gents Sevadal, BalVikas etc. These various Centers and Vibhags have all done wonderful work in their own silos. The hope of this SSSVJ program is to use it as a tool to create and also increase the collaboration, interaction and engagement of the existing SSSO members.</li>
                    <li><span>Outreach:</span> The message of Bhagawan is invaluable and priceless. It has helped millions. The intention of this objective is to share these treasures with as many people as possible. Our goal is not “missionary” in that there is no concept of conversion of anyone into some exclusive group or organisation. Bhagawan’s mission is universal. Instead, our intent is to reach out to as many different segments of society to spread the golden message of Bhagawan. The best way to communicate and share the message of Bhagawan is to provide the world tools and platforms to explore and become aware of the messages. SSSVJ is a tool for existing volunteers to gain deeper insights and for newcomers to become aware of the various deep insights Bhagawan has shared with all of us</li>
                    </ol>
                    <br></br><br></br>
                    <span>Implementation of the Programme</span>
                    <br></br>
                    The program is implemented over four phases viz. Energise, Empower, Equip and Evolve. Each of the phases has been designed to enable meticulous implementation of the program. 
                    <br></br><br></br>
                    The ‘Energise’ phase attempts to create interest, enthusiasm and fervour in students, teachers, school staff, parents and community.
                    <br></br><br></br>
                    ‘Empower’ phase establishes the commitment of Sri Sathya Sai Seva Organisation to the school by improving the overall well being of the children and implement programs that will enhance their capabilities, character and capacity. This stage brings out the willingness, enthusiasm, participation and capability of the schools through the children, teachers and parents.
                    <br></br><br></br>
                    The ‘Equip’ phase is on cost-intensive civil work like repairs to the dilapidated infrastructure, the building of additional classrooms, repairs to damaged floors or leaky roofs, construction of compound walls, whitewashing of walls etc. Thus the physical condition and appearance of the schools get transformed and there is the motivation for the students to attend school.	
                    <br></br><br></br>
                    In the ‘Evolve’ phase, the school is connected with the larger community by engaging the students in community competitions, linking schools and implementing Government policy to meet deficiencies, if any.
                    <br></br><br></br>
                    In the state of Goa, 06 schools have been adopted under SSSVJ Program as on November 2022.
                    <br></br><br></br>
                    <TableContainer component={Paper} style={{ maxWidth:"550px" }}>
                        <Table style={{ tableLayout: 'fixed', maxWidth:"550px" }} >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ maxWidth: '200px' }}>Sr. No.</TableCell>
                                    <TableCell>Name of the Schools</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                <TableRow>
                                    <TableCell>North Goa</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>Government Primary School, Neura</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>2</TableCell>
                                    <TableCell>Madkaikar Navchaitanya High School, Corlim.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>3</TableCell>
                                    <TableCell>Government Primary School, Mangueshi</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>South Goa</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>4</TableCell>
                                    <TableCell>Government Primary School, Baina, Vasco</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>5</TableCell>
                                    <TableCell>New Era High School, Margao</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>6</TableCell>
                                    <TableCell>Government High School, Savoi Verem</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br></br><br></br>
                    <span>Activities conducted in adopted schools under SSSVJ</span>
                    <br></br><br></br>
                    <ul>
                    <li>Value Education classes (Balvikas class)</li>
                    <li>Medical Camps (Dental, eye check-up, General) and Periodical health check-ups of children and teachers</li>
                    <li>Various competitions (Drawing, Singing, Dance, essay,…)</li>
                    <li>Art workshop</li>
                    <li>Yoga classes</li>
                    <li>Celebration of various festivals (Ganesh Chaturthi, Diwali, Swamis Birthday,Onam, Christmas, Guru Pournima,…)</li>
                    <li>Tree Plantation</li>
                    <li>Cleanliness drive</li>
                    <li>Sports Activities --- Indoor and outdoor games</li>
                    <li>Awareness camps on Nutrition, Health and Hygiene, Good and Bad touch, environment cleanliness,….</li>
                    <li>Matru-Pitru Poojan on the occasion of Guru Pournima</li>
                    <li>Providing Parenting tips</li>
                    <li>Providing teaching aids</li>
                    <li>Counselling children and teachers</li>
                    <li>Supporting children having challenges in studies</li>
                    <li>Soft Skill Development for teachers</li>
                    <li>Personality development classes for the children</li>
                    <li>Providing infrastructure support to the schools by undertaking repairs to the classroom, painting, providing benches, constructing toilets and drinking water facility.</li>
                    </ul>
                    </p>
                    </div>
                    <div className="space"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default SSSVJPage
 